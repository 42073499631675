<template>
  <div>
    <div class="d-sm-flex mb-4">
      <h1 class="h3 mb-0 text-gray-800">FCB Check</h1>
    </div>

    <div class="row">
      <!-- Content Column -->
      <div class="col mb-4 animated bounceInUp">
        <!-- Color System -->
        <div class="card shadow mb-4">
          <div class="card-body">
            <form method="post" @submit.prevent="fcbcall" class="needs-validation">
              <h4>Personal Information</h4>
              <hr class=" ml-3 mr-3 sidebar-divider">

              <div class="form-group row">
                <label for="inputname" class="col-sm-2 col-form-label">Names</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputname" v-model="fcbData.names">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputsurname" class="col-sm-2 col-form-label">Surname</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputsurname" v-model="fcbData.surname">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputdob" class="col-sm-2 col-form-label">Date Of Birth</label>
                <div class="col-sm-10">
                  <input type="date"
                         placeholder="dd-mm-yyyy"
                         value=""
                         class="form-control"
                         required id="inputdob"
                         min="1900-01-01" max="2000-12-31" v-model="fcbData.dob" @change="format">
                </div>

              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">National ID</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" maxlength="12" min="12" required
                         v-model="fcbData.national_id">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Passport Number</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="fcbData.passport">

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Driver's Licence</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="fcbData.drivers_licence">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Gender</label>
                <div class="col-sm-10">
                  <select class="form-control" required v-model="fcbData.gender"
                          @change="showData">
                    <option selected value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Marital Status</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example" v-model="fcbData.married"
                  >
                    <!--                    <option v-for="(option,index) in married_status" :key="index">{{ option }}</option>-->
                    <option value="S">Single</option>
                    <option value="M">Married</option>
                    <option value="D">Divorced</option>
                    <option value="W">Widowed</option>

                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Nationality</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.nationality">
                    <option v-for="(option,index) in countries"
                            :value="index+1"
                            :key="index">{{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Search Purpose</label>
                <div class="col-sm-10">
                  <select class="form-control"
                          required
                          aria-label="Default select example"
                          v-model="fcbData.search_purpose">
                    <option v-for="(option,index) in s_purpose" :value="index+1" :key="index">{{ option }}</option>
                  </select>
                </div>
              </div>

              <hr class=" ml-3 mr-3 sidebar-divider">
              <h4>Contact Information</h4>
              <hr class=" ml-3 mr-3 sidebar-divider">

              <div class="form-group row">
                <label for="inputsno" class="col-sm-2 col-form-label">Street Number</label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" required id="inputsno" v-model="fcbData.streetno">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputstname" class="col-sm-2 col-form-label">Street Name</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputstname" v-model="fcbData.streetname">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputbld" class="col-sm-2 col-form-label">Building</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputbld" v-model="fcbData.building">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputsurb" class="col-sm-2 col-form-label">Surburb</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputsurb" v-model="fcbData.suburb">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputpb" class="col-sm-2 col-form-label">Private Bag</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputpb" v-model="fcbData.pbag">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputcty" class="col-sm-2 col-form-label">City</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputcty" v-model="fcbData.city">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Property Density</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.property_density">
                    <option v-for="(option,index) in p_density"
                            :value="index"
                            :key="index">{{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Property Status</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.property_status">
                    <option v-for="(option,index) in p_status"
                            :value="index"
                            :key="index">{{ option }}
                    </option>
                  </select>

                </div>
              </div>

              <div class="form-group row">
                <label for="inputtel" class="col-sm-2 col-form-label">Telephone</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputtel" v-model="fcbData.telephone">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputmn" class="col-sm-2 col-form-label">Mobile Number</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputmn" v-model="fcbData.mobile">
                </div>
              </div>

              <div class="form-group row">
                <label for="inputidml" class="col-sm-2 col-form-label">Ind. Email</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputidml" v-model="fcbData.ind_email">
                </div>
              </div>

              <hr class=" ml-3 mr-3 sidebar-divider">
              <h4>Employment Details</h4>
              <hr class=" ml-3 mr-3 sidebar-divider">

              <div class="form-group row">
                <label for="inputcn" class="col-sm-2 col-form-label">Company Name</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" required id="inputcn"
                         v-model="fcbData.employmentDetails.company">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Industry</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.employmentDetails.industryOfOrganisation">
                    <option v-for="(option,index) in industry" :key="index">{{ option }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Occupation Class</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.occupation_class">
                    <option :value="index" v-for="(option,index) in occu_class" :key="index">{{ option }}</option>

                  </select>

                </div>
              </div>

              <div class="form-group row">
                <label for="inputmi"
                       class="col-sm-2 col-form-label">Monthly Income</label>
                <div class="col-sm-10">
                  <input type="number"
                         class="form-control"
                         required
                         id="inputmi"
                         v-model="fcbData.employmentDetails.averageMonthlyIncome" @change="fixFormat">
                </div>
              </div>

              <hr class=" ml-3 mr-3 sidebar-divider">
              <h4>Loan Details</h4>
              <hr class=" ml-3 mr-3 sidebar-divider">

              <div class="form-group row">
                <label for="inputla" class="col-sm-2 col-form-label">Loan Amount</label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" required id="inputla" v-model="fcbData.loan_amount">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Loan Purpose</label>
                <div class="col-sm-10">
                  <select class="form-control" required aria-label="Default select example"
                          v-model="fcbData.loan_purpose">
                    <option v-for="(option,index) in loanPurpose"
                            :value="index"
                            :key="index">{{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 text-right">
                  <button type="submit"
                          @click="fcbcall"
                          class="btn btn-success mb-2 btn1">Start Screening
                  </button>
                  <button
                      type="submit"
                      @click="clear"
                      class="btn btn-warning mb-2">Clear Results
                  </button>
                </div>
              </div>
            </form>

          </div>
          <br>
          <semipolar-spinner class="spinner" v-if="isloading"
                             :animation-duration="2000"
                             :size="40"
                             color="#ff1d5e"
          />

          <div v-if="hasNetworkError" class="alert alert-warning" style="color: black; margin: 0 3em 0 3em;">
            There was an error please try again.
          </div>

          <hr class=" ml-3 mr-3 sidebar-divider">
          <div class="card shadow mb-4 ml-4 mr-4" v-if="responsedata">

            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary rheader">Returned Results</h6>
            </div>
            <table class="table table-hover">

              <tbody>

              <tr>
                <th scope="row">ID</th>
                <td></td>
                <td>{{ profiledata.individual.id }}</td>
              </tr>
<!--              <tr>-->
<!--                <th scope="row">FCB Score</th>-->
<!--                <td></td>-->
<!--                <td v-if="profiledata.individual.score < 201" class="table-success">Low Risk</td>-->
<!--                <td v-else-if="profiledata.individual.score < 251" class="table-primary">Medium to Low Risk</td>-->
<!--                <td v-else-if="profiledata.individual.score < 301" class="table-info">Medium to High Risk</td>-->
<!--                <td v-else-if="profiledata.individual.score < 351" class="table-warning"> High Risk</td>-->
<!--                <td v-else-if="profiledata.individual.score < 401" class="table-danger"> Extreme High Risk</td>-->
<!--              </tr>-->


              <tr>
                <th scope="row">Status</th>
                <td></td>
                <td v-if="profiledata.individual.status === 'GREEN'" class="table-secondary">No History with FCB</td>
                <td v-else-if="profiledata.individual.status === 'GOOD'" class="table-success">Clean History with FCB
                </td>
                <td v-else-if="profiledata.individual.status === 'ADVERSE'" class="table-info">Open Adverse Item(s)</td>
                <td v-else-if="profiledata.individual.status === 'FAIR'" class="table-warning">Prior Adverse Item(s)
                </td>
                <td v-else-if="profiledata.individual.status === 'PEP'" class="table-danger">Politically Exposed
                  Persona
                </td>
                <td v-else>null</td>
              </tr>

              <tr>
                <th scope="row">Full Name</th>
                <td></td>
                <td>{{ profiledata.individual.full_Name }}</td>
              </tr>

              <tr>
                <th scope="row">Nationality</th>
                <td></td>
                <td>{{ profiledata.individual.nationality }}</td>
              </tr>

              <tr>
                <th scope="row">Gender</th>
                <td></td>
                <td>{{ profiledata.individual.gender }}</td>
              </tr>

              <tr>
                <th scope="row">Date of Birth</th>
                <td></td>
                <td>{{ profiledata.individual.dob }}</td>
              </tr>

              <tr>
                <th scope="row">Marital Status</th>
                <td></td>
                <td>{{ profiledata.individual.married }}</td>
              </tr>

              <tr>
                <th scope="row">National ID</th>
                <td></td>
                <td>{{ profiledata.individual.national_ID }}</td>
              </tr>

              <tr>
                <th scope="row">Driver's License</th>
                <td></td>
                <td>{{ profiledata.individual.license }}</td>
              </tr>

              <tr>
                <th scope="row">Email Address</th>
                <td></td>
                <td>{{ profiledata.individual.email }}</td>
              </tr>

              <tr>
                <th scope="row">Phone Number</th>
                <td></td>
                <td>{{ profiledata.individual.phone }}</td>
              </tr>


              <tr class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion1"
                  href="#collapseOne">
                <th scope="row">Addresses</th>

                <td></td>
                <td v-if="profiledata.addresses.length > 0" class="expand-button">{{ profiledata.addresses.length }}
                  results
                </td>
                <td v-else>No results found</td>

              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div class="collapse in p-3" v-for="(detail, index) in profiledata.addresses"
                       :key='index'>
                    <div class="row">
                      <div class="col">Date Searched:</div>
                      <div class="col">{{ detail.date_searched }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Street Number:</div>
                      <div class="col">{{ detail.street_no }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Street Name:</div>
                      <div class="col">{{ detail.street_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Surburb:</div>
                      <div class="col">{{ detail.surburb }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Building:</div>
                      <div class="col">{{ detail.building }}</div>
                    </div>
                    <div class="row">
                      <div class="col">City:</div>
                      <div class="col">{{ detail.city }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Country:</div>
                      <div class="col">{{ detail.country }}</div>
                    </div>

                    <div class="row">
                      <div class="col">Property Status:</div>
                      <div class="col">{{ detail.property_status }}</div>
                    </div>

                    <div class="row">
                      <div class="col">Property Density:</div>
                      <div class="col">{{ detail.property_density }}</div>
                    </div>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                  </div>
                </td>
              </tr>


              <tr class="accordion-toggle collapsed" id="accordion1" data-toggle="collapse" data-parent="#accordion1"
                  href="#collapseOne">
                <th scope="row">Searches</th>

                <td></td>
                <td v-if="profiledata.searches.length > 0" class="expand-button">{{ profiledata.searches.length }}
                  results
                </td>
                <td v-else>No results found</td>

              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div id="collapseOne" class="collapse in p-3" v-for="(detail, index) in profiledata.searches"
                       :key='index'>
                    <div class="row">
                      <div class="col">Date Searched:</div>
                      <div class="col">{{ detail.date_searched }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Status:</div>
                      <div class="col text-secondary" v-if="detail.status === 'GREEN'">No History with FCB</div>
                      <div class="col text-success" v-else-if="detail.status === 'GOOD'">Clean History with FCB</div>
                      <div class="col text-info" v-else-if="detail.status === 'ADVERSE'">Open Adverse Item(s)</div>
                      <div class="col text-warning" v-else-if="detail.status === 'FAIR'">Prior Adverse Item(s)</div>
                      <div class="col text-danger" v-else-if="detail.status === 'PEP'">Politically Exposed Persona</div>
                      <div class="col" v-else>null</div>

                    </div>
                    <div class="row">
                      <div class="col">Search Purpose:</div>
                      <div class="col">{{ detail.search_purpose }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Subscriber Short Name:</div>
                      <div class="col">{{ detail.subscriber_short_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Subscriber Name:</div>
                      <div class="col">{{ detail.subscriber_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Branch Name:</div>
                      <div class="col">{{ detail.branch_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Score:</div>

                      <div v-if="detail.score < 201" class="col text-danger">Extreme High Risk</div>
                      <div v-else-if="detail.score < 251" class="col text-warning">High Risk</div>
                      <div v-else-if="detail.score < 301" class="col text-info">Medium to High Risk</div>
                      <div v-else-if="detail.score < 351" class="col text-primary">Medium to Low Risk</div>
                      <div v-else-if="detail.score < 401" class="col text-success">Low Risk</div>
                    </div>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                  </div>
                </td>
              </tr>

              <tr class="accordion-toggle collapsed" id="accordion2" data-toggle="collapse" data-parent="#accordion2"
                  href="#collapseTwo">
                <th scope="row">Directorships</th>

                <td></td>
                <td v-if="profiledata.directorships.length > 0" class="expand-button">
                  {{ profiledata.directorships.length }} results
                </td>
                <td v-else>No results found</td>
              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div id="collapseTwo" class="collapse in p-3" v-for="(detail, index) in profiledata.directorships"
                       :key='index'>
                    <div class="row">
                      <div class="col">Date Searched:</div>
                      <div class="col">{{ detail.date_searched }}</div>
                    </div>

                    <div class="row">
                      <div class="col">Company Name:</div>
                      <div class="col">{{ detail.company_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Trade Name:</div>
                      <div class="col">{{ detail.trade_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Registration Number:</div>
                      <div class="col">{{ detail.registration_number }}</div>
                    </div>
                    <div class="row">
                      <div class="col">DOF:</div>
                      <div class="col">{{ detail.dof }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Status:</div>
                      <div class="col text-secondary" v-if="detail.status === 'GREEN'">No History with FCB</div>
                      <div class="col text-success" v-else-if="detail.status === 'GOOD'">Clean History with FCB</div>
                      <div class="col text-info" v-else-if="detail.status === 'ADVERSE'">Open Adverse Item(s)</div>
                      <div class="col text-warning" v-else-if="detail.status === 'FAIR'">Prior Adverse Item(s)</div>
                      <div class="col text-danger" v-else-if="detail.status === 'PEP'">Politically Exposed Persona</div>
                      <div class="col" v-else>null</div>

                    </div>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                  </div>
                </td>
              </tr>


              <tr class="accordion-toggle collapsed" id="accordion3" data-toggle="collapse" data-parent="#accordion3"
                  href="#collapseThree">
                <th scope="row">Settled Credit Events</th>

                <td></td>
                <td v-if="profiledata.settled_credit_events.length > 0" class="expand-button">
                  {{ profiledata.settled_credit_events.length }} results
                </td>
                <td v-else>No results found</td>

              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div id="collapseThree" class="collapse in p-3"
                       v-for="(detail, index) in profiledata.settled_credit_events" :key='index'>
                    <div class="row">
                      <div class="col">Counterparty:</div>
                      <div class="col">{{ detail.counterparty }}</div>
                    </div>

                    <div class="row">
                      <div class="col">Branch:</div>
                      <div class="col">{{ detail.branch }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Amount:</div>
                      <div class="col">{{ detail.amount }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Event Type:</div>
                      <div class="col">{{ detail.event_type }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Currency:</div>
                      <div class="col">{{ detail.currency }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Comment:</div>
                      <div class="col">{{ detail.comment }}</div>
                    </div>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                  </div>
                </td>
              </tr>

              <tr class="accordion-toggle collapsed" id="accordion4" data-toggle="collapse" data-parent="#accordion4"
                  href="#collapseFour">
                <th scope="row">Unsettled Credit Events</th>

                <td></td>
                <td v-if="profiledata.active_credit_events.length > 0" class="expand-button">
                  {{ profiledata.active_credit_events.length }} results
                </td>
                <td v-else>No results found</td>

              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div id="collapseFour" class="collapse in p-3" v-for="(detail, index) in profiledata.active"
                       :key='index'>
                    <div class="row">
                      <div class="col">Counterparty:</div>
                      <div class="col">{{ detail.counterparty }}</div>
                    </div>

                    <div class="row">
                      <div class="col">Branch:</div>
                      <div class="col">{{ detail.branch }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Amount:</div>
                      <div class="col">{{ detail.amount }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Event Type:</div>
                      <div class="col">{{ detail.event_type }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Currency:</div>
                      <div class="col">{{ detail.currency }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Comment:</div>
                      <div class="col">{{ detail.comment }}</div>
                    </div>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                  </div>
                </td>
              </tr>

              </tbody>
            </table>

          </div>
        </div>

      </div>
      <div class="col-lg-4">
        <div class="card position-relative">
          <div class="card-body">
            <img class="card-img-top"
                 src="../../assets/img/undraw_Hire_re_gn5j.svg">
            <hr>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <div class="container" id="capturePdf">
            <div class="row">
              <div class="col-md-12 body-main">

                <br/>

                <div>
                  <table class="table table-hover">

                    <tbody>
                    <tr>

                      <td>Person Name / Names</td>
                      <td></td>
                      <td>{{ profiledata.firstName }}</td>
                    </tr>
                    <tr>

                      <td>Person Surname</td>
                      <td></td>
                      <td>{{ profiledata.surname }}</td>
                    </tr>
                    <tr>

                      <td>Person ID Number</td>
                      <td></td>
                      <td v-if="profiledata.status === 'E'" class="table-danger">{{ profiledata.personNo }} Not Valid
                      </td>
                      <td v-else>{{ profiledata.personNo }}</td>
                    </tr>
                    <tr>

                      <td>Person Gender</td>
                      <td></td>
                      <td v-if="profiledata.sex === 'F'">Female</td>
                      <td v-else-if="profiledata.sex === 'M'">Male</td>
                      <td v-else>Null</td>
                    </tr>

                    <tr>

                      <td>Person Date Of Birth</td>
                      <td></td>
                      <td>{{ profiledata.dob }}</td>
                    </tr>
                    <tr>

                      <td>Person Birth Place</td>
                      <td></td>
                      <td>{{ profiledata.birthPlace }}</td>
                    </tr>
                    <tr v-if="profiledata.status === 'D'">

                      <td>Person Date Of Death</td>
                      <td></td>
                      <td>{{ profiledata.dateOfDeath }}</td>
                    </tr>

                    <tr>

                      <td>Person Life Status</td>
                      <td></td>
                      <td v-if="profiledata.status === 'A'" class="table-success">Active</td>
                      <td v-else-if="profiledata.status === 'E'" class="table-danger">Record Error / Not Valid</td>
                      <td v-else-if="profiledata.status === 'D'" class="table-warning">Deceased</td>
                      <td v-else>Null</td>
                    </tr>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                    </tbody>
                  </table>

                </div>
                <div>
                  <div class="col-md-12">
                    <p><b>Date :</b>
                      {{ new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() }}</p><br/>
                    <p><b>Signature</b></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <button type="submit" @click="generatePDF" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i class="fas fa-file-pdf fa-sm text-white-50 pdf"></i> Generate Report
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dashboard from "../../layouts/dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {SemipolarSpinner} from 'epic-spinners';
import moment from 'moment';
import {required, minLength, maxLength, requiredUnless, requiredIf} from 'vuelidate/lib/validators';

export default {
  name: "FCB",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
  },
  data() {
    return {

      fcbData: {
        dob: '',
        names: '',
        surname: '',
        national_id: '',
        gender: '',
        search_purpose: '',
        drivers_licence: '',
        passport: '',
        married: '',
        nationality: '',
        streetno: 0,
        streetname: '',
        building: '',
        suburb: '',
        pbag: '',
        city: '',
        telephone: '',
        mobile: '',
        ind_email: '',
        property_density: '',
        property_status: '',
        occupation_class: 0,
        employmentDetails: {
          industryOfOrganisation: '',
          averageMonthlyIncome: 0,
          company: ''
        },
        loan_purpose: '',
        loan_amount: ''
      },

      responsedata: false,
      profiledata: "",
      isloading: false,
      hasNetworkError: false,
      submitted: false,

      countries: [
        "AFGHANISTAN", "ALBANIA", "ZIMBABWE", "ALGERIA", "AMERICAN SAMOA", "ANDORRA", "ANGOLA", "ANGUILLA", "ANTARCTICA", "ANTIGUA AND BARBUDA", "ARGENTINA", "ARMENIA", "ARUBA", "AUSTRALIA", "AUSTRIA", "AZERBAIJAN",
        "BAHAMAS", "BAHRAIN", "BANGLADESH", "BARBADOS", "BELARUS", "BELGIUM", "BELIZE", "BENIN", "BERMUDA", "BHUTAN", "BOLIVIA", "BOSNIA AND HERZEGOVINA", "BOTSWANA", "BOUVET ISLAND", "BRAZIL", "BRITISH INDIAN OCEAN TERRITORY",
        "BRUNEI DARUSSALAM", "BULGARIA", "BURKINA FASO", "BURUNDI", "CAMBODIA", "CAMEROON", "CANADA", "CAPE VERDE", "CAYMAN ISLANDS", "CENTRAL AFRICAN REPUBLIC", "CHAD", "CHILE", "CHINA", "CHRISTMAS ISLAND", "COCOS (KEELING) ISLANDS",
        "COLOMBIA", "COMOROS", "CONGO", "CONGO", "COOK ISLANDS", "COSTA RICA", "COTE D'IVOIRE", "CROATIA", "CUBA", "CYPRUS", "CZECH REPUBLIC", "DENMARK", "DJIBOUTI", "DOMINICA", "DOMINICAN REPUBLIC", "ECUADOR", "EGYPT", "EL SALVADOR",
        "EQUATORIAL GUINEA", "ERITREA", "ESTONIA", "ETHIOPIA", "FALKLAND ISLANDS (MALVINAS)", "FAROE ISLANDS", "FIJI", "FINLAND", "FRANCE", "FRENCH GUIANA", "FRENCH POLYNESIA", "FRENCH SOUTHERN TERRITORIES", "GABON", "GAMBIA", "GEORGIA",
        "GERMANY", "GHANA", "GIBRALTAR", "GREECE", "GREENLAND", "GRENADA", "GUADELOUPE", "GUAM", "GUATEMALA", "GUINEA", "GUINEA-BISSAU", "GUYANA", "HAITI", "HEARD ISLAND AND MCDONALD ISLANDS", "HOLY SEE (VATICAN CITY STATE)", "HONDURAS",
        "HONG KONG", "HUNGARY", "ICELAND", "INDIA", "INDONESIA", "IRAN", "IRAQ", "IRELAND", "ISRAEL", "ITALY", "JAMAICA", "JAPAN", "JORDAN", "KAZAKHSTAN", "KENYA", "KIRIBATI", "KOREA", "KOREA", "KUWAIT", "KYRGYZSTAN", "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        "LATVIA", "LEBANON", "LESOTHO", "LIBERIA", "LIBYAN ARAB JAMAHIRIYA", "LIECHTENSTEIN", "LITHUANIA", "LUXEMBOURG", "MACAO", "MACEDONIA", "MADAGASCAR", "MALAWI", "MALAYSIA", "MALDIVES", "MALI", "MALTA", "MARSHALL ISLANDS", "MARTINIQUE", "MAURITANIA",
        "MAURITIUS", "MAYOTTE", "MEXICO", "MICRONESIA", "MOLDOVA", "MONACO", "MONGOLIA", "MONTSERRAT", "MOROCCO", "MOZAMBIQUE ", "MYANMAR", "NAMIBIA", "NAURU", "NEPAL", "NETHERLANDS", "NETHERLANDS ANTILLES", "NEW CALEDONIA", "NEW ZEALAND", "NICARAGUA",
        "NIGER", "NIGERIA", "NIUE", "NORFOLK ISLAND", "NORTHERN MARIANA ISLANDS", "NORWAY", "OMAN", "PAKISTAN", "PALAU", "PALESTINIAN TERRITORY", "PANAMA", "PAPUA NEW GUINEA", "PARAGUAY", "PERU", "PHILIPPINES", "PITCAIRN", "POLAND", "PORTUGAL", "PUERTO RICO",
        "QATAR", "REUNION", "ROMANIA", "RUSSIAN FEDERATION", "RWANDA", "SAINT HELENA", "SAINT KITTS AND NEVIS", "SAINT LUCIA", "SAINT PIERRE AND MIQUELON", "SAINT VINCENT AND THE GRENADINES", "SAMOA", "SAN MARINO", "SAO TOME AND PRINCIPE", "SAUDI ARABIA",
        "SENEGAL", "SERBIA AND MONTENEGRO", "SEYCHELLES", "SIERRA LEONE", "SINGAPORE", "SLOVAKIA", "SLOVENIA", "SOLOMON ISLANDS", "SOMALIA", "SOUTH AFRICA", "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", "SPAIN", "SRI LANKA", "SUDAN", "SURINAME",
        "SVALBARD AND JAN MAYEN", "SWAZILAND", "SWEDEN", "SWITZERLAND", "SYRIAN ARAB REPUBLIC", "TAIWAN", "TAJIKISTAN", "TANZANIA", "THAILAND", "TIMOR-LESTE", "TOGO", "TOKELAU", "TONGA", "TRINIDAD AND TOBAGO", "TUNISIA", "TURKEY", "TURKMENISTAN",
        "TURKS AND CAICOS ISLANDS", "TUVALU", "UGANDA", "UKRAINE", "UNITED ARAB EMIRATES", "UNITED KINGDOM", "UNITED STATES", "UNITED STATES MINOR OUTLYING ISLANDS", "URUGUAY", "UZBEKISTAN", "VANUATU", "VENEZUELA", "VIET NAM", "VIRGIN ISLANDS",
        "VIRGIN ISLANDS", "WALLIS AND FUTUNA", "WESTERN SAHARA", "YEMEN", "ZAMBIA"
      ],

      p_status: ["Owned", "Rented", "Mortgaged", "Parents", "Employer Owned"],
      p_density: ["Low", "Medium", "High", "Rural", "Industrial"],
      occu_class: ["N/A", "MANAGER", "PROFESSIONAL", "TECHNICIAN AND ASSOCIATE PROFESSIONAL", "CLERICAL SUPPORT WORKER", "SERVICE & SALES WORKERS", "SKILLED AGRICULTURAL FORESTRY & FISHERY WORKER CRAFT & RELATED TRADES WORKER", "PLANT & MACHINE OPERATOR & ASSEMBLER ELEMENTARY OCCUPANT", "ARMED FORCES OCCUPANT", "UNEMPLOYED"],
      loanPurpose: ["Current Account Overdraft", "Personal Loan Account", "Auto Loan", "Educational Loan", "Home Improvement Loan", "Consolidation Loan", "Credit Card", "Line Of Credit", "Revolving Credit", "Business Asset Loan", "Business Improvement Loan", "Renewable Energy Loan", "Wholesale Lending", "Other"],
      industry: ["Agriculture", "Manufacturing", "Mining/Quarrying", "Energy/Water", "Trade", "Tourism/Restaurant/Hotels", "Transport", "Real Estate", "Finance", "Government", "Other", "Non/Unemployed", "Health", "Unknown", "Private Security", "Police", "Army", "Prisons & Correctional Services", "ICT / Communications", "Retail"],
      s_purpose: ["KYC", "NEW LOAN", "REPEAT LOAN", "PERIODIC REVIEW", "NON PERF A/C", "REC./COLLECT", "SUSPICIOUS ACT.", "COMP. CLOSURE", "OTHER", "MEDICAL/HEALTH", "INSURANCE", "SCHOOL LOANS"],
      genders: ["MALE", "FEMALE"],
    }
  },

  computed: {
    isLocal() {
      return this.nationality === 3;
    }
  },

  methods: {
    showData() {
      console.log(this.fcbData);
    },

    sortByAlphabetical() {

    },

    async fcbcall() {

      // console.log(this.fcbData);

      this.submitted = true;

      //force cast some params to numbers --
      this.fcbData.employmentDetails.averageMonthlyIncome = +this.fcbData.employmentDetails.averageMonthlyIncome;
      this.fcbData.loan_amount = +this.fcbData.loan_amount;
      this.fcbData.streetno = +this.fcbData.streetno;

      this.isloading = true;
      console.log(JSON.stringify(this.fcbData));

      try {
        let JWTToken = this.$store.getters.accessToken;
        axios.post(
            'https://ekyctest.xarani.net/service/fcb/',
            this.fcbData, {headers: {"Authorization": `Bearer ${JWTToken}`, 'Content-Type': 'application/json'}})
            .then(res => {
              if (res.status === 200) {
                this.profiledata = res.data;
                console.log('profile is:', res.data);
                console.log(new Date().getMonth() + 1 + '-' + new Date().getFullYear());
                this.responsedata = true;
                this.isloading = false;
              } else {
                window.clearTimeout(this.timeoutID);
                this.$store.commit("clearUserData");
                alert('Your Session has ended');
                // this.$router.push("/login");
                window.location.href = '/login';
              }

            })
            .catch(error => console.log(error))
      } catch (e) {
        console.error(e)
        this.isloading = false;
        this.hasNetworkError = true;
      }

    },

    async generatePDF() {
      let pdf = new jsPDF("p", "pt", "a2");
      let element = document.getElementById('capturePdf');
      let width = element.style.width;
      let height = element.style.height;
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 2, 2, width, height);
        pdf.save('test.pdf')
      });
    },

    async format() {
      this.dob = moment(String(this.dob)).format('DD-MM-YYYY')
    },

    async fixFormat() {
      //this.averageMonthlyIncome = Number(this.Income).toFixed(2);
      this.averageMonthlyIncome = Number(this.Income);
      //   this.loan_amount = Number(this.amount).toFixed(2);
      this.loan_amount = Number(this.amount);
    },

    async clear() {
      this.responsedata = false;
    },


  },

};
</script>

<style scoped>
.pdf {
  color: red
}

.spinner {
  margin-left: 30em;
}

.body-main {
  background: #ffffff;
  border-bottom: 15px solid #1E1F23;
  border-top: 15px solid #1E1F23;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px !important;
  position: relative;
  box-shadow: 0 1px 21px #808080;
  font-size: 10px;
  width: 200px;
}

.main thead {
  background: #1E1F23;
  color: #fff
}

.img {
  height: 80px
}

h1, .rheader {
  text-align: center
}


tr.hide-table-padding td {
  padding: 0;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-';
}

.accordion-toggle.collapsed .expand-button:after {
  content: '+';
}

.btn1 {
  margin-right: 2em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>