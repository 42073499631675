var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",attrs:{"id":"accordionSidebar"}},[_vm._m(0),_c('hr',{staticClass:"sidebar-divider my-0"}),_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link collapsed",attrs:{"to":"/"}},[_c('i',{staticClass:"fas fa-fw fa-tachometer-alt"}),_c('span',[_vm._v("Dashboard")])])],1),_c('hr',{staticClass:"sidebar-divider"}),_c('div',{staticClass:"sidebar-heading"},[_vm._v(" e-KYC Services ")]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link collapsed",attrs:{"to":"/identity"}},[_c('i',{staticClass:"fas fa-fw fa-id-card"}),_c('span',[_vm._v("Identity Check")])])],1),_c('hr',{staticClass:"sidebar-divider d-none d-md-block"}),(false)?_c('li',{staticClass:"nav-item"},[_vm._m(1),_vm._m(2)]):_vm._e(),(false)?_c('hr',{staticClass:"sidebar-divider"}):_vm._e(),(false)?_c('div',{staticClass:"sidebar-heading"},[_vm._v(" Addons ")]):_vm._e(),(false)?_c('li',{staticClass:"nav-item"},[_vm._m(3),_vm._m(4)]):_vm._e(),(false)?_c('li',{staticClass:"nav-item"},[_vm._m(5)]):_vm._e(),(false)?_c('li',{staticClass:"nav-item"},[_vm._m(6)]):_vm._e(),(false)?_c('hr',{staticClass:"sidebar-divider d-none d-md-block"}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"sidebar-brand d-flex align-items-center justify-content-center",staticStyle:{"margin-top":"90px"},attrs:{"href":"index.html"}},[_c('div',{staticClass:"sidebar-brand-icon"},[_c('img',{staticClass:"card-img-top",attrs:{"src":require("../../assets/img/xarani.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link collapsed",attrs:{"href":"#","data-toggle":"collapse","data-target":"#collapseUtilities","aria-expanded":"true","aria-controls":"collapseUtilities"}},[_c('i',{staticClass:"fas fa-fw fa-wrench"}),_c('span',[_vm._v("Utilities")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse",attrs:{"id":"collapseUtilities","aria-labelledby":"headingUtilities","data-parent":"#accordionSidebar"}},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},[_c('h6',{staticClass:"collapse-header"},[_vm._v("Custom Utilities:")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"utilities-color.html"}},[_vm._v("Colors")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"utilities-border.html"}},[_vm._v("Borders")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"utilities-animation.html"}},[_vm._v("Animations")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"utilities-other.html"}},[_vm._v("Other")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link collapsed",attrs:{"href":"#","data-toggle":"collapse","data-target":"#collapsePages","aria-expanded":"true","aria-controls":"collapsePages"}},[_c('i',{staticClass:"fas fa-fw fa-folder"}),_c('span',[_vm._v("Pages")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse",attrs:{"id":"collapsePages","aria-labelledby":"headingPages","data-parent":"#accordionSidebar"}},[_c('div',{staticClass:"bg-white py-2 collapse-inner rounded"},[_c('h6',{staticClass:"collapse-header"},[_vm._v("Login Screens:")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"login.html"}},[_vm._v("Login")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"register.html"}},[_vm._v("Register")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"forgot-password.html"}},[_vm._v("Forgot Password")]),_c('div',{staticClass:"collapse-divider"}),_c('h6',{staticClass:"collapse-header"},[_vm._v("Other Pages:")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"404.html"}},[_vm._v("404 Page")]),_c('a',{staticClass:"collapse-item",attrs:{"href":"blank.html"}},[_vm._v("Blank Page")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",attrs:{"href":"charts.html"}},[_c('i',{staticClass:"fas fa-fw fa-chart-area"}),_c('span',[_vm._v("Charts")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",attrs:{"href":"tables.html"}},[_c('i',{staticClass:"fas fa-fw fa-table"}),_c('span',[_vm._v("Tables")])])
}]

export { render, staticRenderFns }