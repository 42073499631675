<template>
  <div class="">
    <div class="row p-3">
      <div class="col-9">
        <h5>Batch Upload Results</h5>
      </div>
      <div class="col">
        <button
            @click="downloadCSVData"
            class="btn btn-primary">
          <i class="fa fa-download mr-1"></i>
          <span>Download CSV</span>
        </button>
      </div>
    </div>

    <div v-show="isLoading" class="container">
      <div class="row justify-content-center align-items-center">
        <i class="spinner-border spinner"></i>
      </div>
    </div>

    <table v-show="!isLoading" class="table">
      <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">First Name(s)</th>
        <th scope="col">LastName</th>
        <th scope="col">ID Number</th>
        <th scope="col">Gender</th>
        <th scope="col">DOB</th>
        <th scope="col">Birth Place</th>
      </tr>
      </thead>
      <tbody>
      <tr :key="item.id" v-for="(item) in results">
        <th scope="row">{{ item.id }}</th>
        <td>{{ item.firstName }}</td>
        <td>{{ item.surname }}</td>
        <td>{{ item.personNo }}</td>
        <td>{{ item.sex === 'M' ? 'Male' : 'Female' }}</td>
        <td>{{ item.dateOfBirth }}</td>
        <td>{{ item.birthPlace }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "BatchUploadResults",
  mounted() {
    this.batchId = this.$route.params.batchId;
    this.isLoading = true;
    this.getCsvResults();
  },
  data() {
    return {
      batchId: "",
      isLoading: false,
      results: null,
      filename: Date().toString().replaceAll(" ", "_").replaceAll(':', "_") + ".csv"
    }
  },
  methods: {
    getCsvResults() {
      let JWTToken = this.$store.getters.accessToken;
      axios.get(`http://10.170.4.68:7908/api/batch-results/${this.batchId}`,
          {headers: {"Authorization": `Bearer ${JWTToken}`}}
      ).then((response) => {
        this.isLoading = false;
        this.results = response.data;
        console.log(response);
      }).catch((error) => {
        this.isLoading = true;
        console.log(error);
      })
    },

    downloadCSVData() {
      let csv = 'Row Id,First Name, Surname, ID Number, Sex ,DOB, Birth Place,Date Of Death\n';
      this.results.forEach((row) => {
        let f =
            row.id + ' ,' + row.firstName + ' ,' +
            row.surname + ' ,' + row.personNo + ' ,' +
            row.sex + ' ,' + row.dateOfBirth + ' ,' +
            row.birthPlace + ' ,' + row.dateOfDeath + ' ,';
        csv += f;
        csv += "\n";
      });
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = this.filename;
      anchor.click();
    }
  },
}
</script>

<style scoped>

</style>