<template>
  <div>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Liveness Check</h1>

    </div>

    <div class="row">
      <!-- Content Column -->
      <div class="col-lg-8 mb-4 animated bounceInUp">
        <!-- Color System -->

        <div class="card shadow mb-4">
          <div class="card-body">

            <form method="post" @submit.prevent="livenesscall">

              <div class="form-group row">
                <label for="inputid" class="col-sm-2 col-form-label">ID Number</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputid" v-model="id_number"
                         :class="{ 'is-invalid': submitted && $v.id_number.$error }">
                  <div v-if="submitted && $v.id_number.$error" class="invalid-feedback">
                    <span v-if="!$v.id_number.required">ID Number is required</span>
                    <span
                        v-if="!$v.id_number.minLength || !$v.id_number.maxLength">ID Number must be 12 characters</span>

                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="column im">
                  <input type='file' @change="handleImage" ref="file" name="im1" accept="image/*">
                  <img class="im1" alt="your image" :src="base64img"/>
                </div>

              </div>
              <div class="col-sm-12 text-right">
                <button type="submit" @click="livenesscall" class="btn btn-success mb-2 btn1">Check Liveness</button>
                <button type="button" @click="clear" class="btn btn-warning mb-2">Clear Results</button>

              </div>
            </form>

          </div>
          <br>
          <semipolar-spinner class="spinner" v-if="isloading"
                             :animation-duration="2000"
                             :size="40"
                             color="#ff1d5e"
          />
          <hr class=" ml-3 mr-3 sidebar-divider">
          <div class="card shadow mb-4 ml-4 mr-4" v-if="responsedata">

            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Returned Results</h6>
            </div>
            <table class="table table-hover">

              <thead>


              <tr>
                <th>Liveness Score</th>
                <td>{{ profiledata.liveNessScore }}</td>
              </tr>


              </thead>
            </table>

          </div>
        </div>

      </div>
      <div class="col-lg-4">

        <div class="card position-relative">
          <div class="card-body">
            <img class="card-img-top"
                 src="../../assets/img/undraw_Hire_re_gn5j.svg">
            <hr>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dashboard from "../../layouts/dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {SemipolarSpinner} from 'epic-spinners';
import {required, minLength, maxLength, requiredUnless, requiredIf} from 'vuelidate/lib/validators';

export default {
  name: "Sanctions",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
  },
  data() {
    return {
      id_number: "",
      base64img: "http://placehold.it/180",
      imageSrc: null,
      responsedata: false,
      profiledata: "",
      isloading: false,
      submitted: false,

    }
  },
  validations: {
    id_number: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(12),
    }
  },
  methods: {
    async livenesscall(e) {
      this.submitted = true;
      const file = this.$refs.file.files[0];


      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid || !file) {
        if (!file) {
          e.preventDefault();
          alert('No file chosen');
          return;
        } else {

          return;
        }
      } else {

        this.imageSrc = this.base64img.split(",")[1]
        const data = {
          subject_id: this.id_number,
          image: this.imageSrc

        }
        this.isloading = true;
        await new Promise(resolve => setTimeout(resolve, 2000));
        try {
          let JWTToken = this.$store.getters.accessToken;
          axios.post('https://ekyctest.xarani.net/service/liveness/', data, {headers: {"Authorization": `Bearer ${JWTToken}`}})
              .then(res => {
                if (res.status === 200) {
                  this.profiledata = res.data;
                  console.log('profile is:', res.data);
                  console.log(new Date().getMonth() + 1 + '-' + new Date().getFullYear());
                  this.responsedata = true;
                  this.isloading = false;
                } else {
                  window.clearTimeout(this.timeoutID);
                  this.$store.commit("clearUserData");
                  alert('Your Session has ended');
                  // this.$router.push("/login");
                  window.location.href = '/login';
                }

              })
              .catch(error => console.log(error))
        } catch (e) {
          console.error(e)
        }
      }
    },

    async generatePDF() {
      let pdf = new jsPDF("p", "pt", "a2");
      let element = document.getElementById('capturePdf');
      let width = element.style.width;
      let height = element.style.height;
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 2, 2, width, height);
        pdf.save('test.pdf')
      });
    },

    async clear() {
      this.responsedata = false,
          this.id_number = "",
          this.base64img = "http://placehold.it/180",
          this.imageSrc = null

    },

    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.base64img = e.target.result;
        this.uploadImage();
      };
      reader.readAsDataURL(fileObject);
    },

  },

};
</script>

<style scoped>
.pdf {
  color: red
}

.spinner {
  margin-left: 30em;
}

.body-main {
  background: #ffffff;
  border-bottom: 15px solid #1E1F23;
  border-top: 15px solid #1E1F23;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px !important;
  position: relative;
  box-shadow: 0 1px 21px #808080;
  font-size: 10px;
  width: 200px;
}

.main thead {
  background: #1E1F23;
  color: #fff
}

.img {
  height: 80px
}

.im1 {
  max-width: 180px !important;
  max-height: 180px !important
}

h1 {
  text-align: center
}

tr.hide-table-padding td {
  padding: 0;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-';
}

.accordion-toggle.collapsed .expand-button:after {
  content: '+';
}

.btn1 {
  margin-right: 2em;
}
</style>