<template>
  <div>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Face Compare</h1>

    </div>

    <div class="row">
      <!-- Content Column -->
      <div class="col-lg-8 mb-4 animated bounceInUp">
        <!-- Color System -->

        <div class="card shadow mb-4">
          <div class="card-body">

            <form class="input-group"  method="post" @submit.prevent="comparecall" enctype="multipart/form-data">
       <div class="row">
  <div class="column im vl">
  <input type='file' name="im1" @change="handleImage" accept="image/*" ref="file"/>
    <img class="im1" alt="your image" :src="base64img"/>
  </div>
  
  <div class="column im">
  <input type='file'   name="im2" @change="handleImage1" accept="image/*" ref="file1"/>
    <img class="im1" alt="your image" :src="base64img1"/>
  </div>

</div>
                            <hr>
                          <div class="col-sm-12 text-right">
                <button type="submit"  @click="comparecall" class="btn btn-success mb-2 btn1">Compare</button>
                <button type="button" @click="clear" class="btn btn-warning mb-2">Clear Results</button>
              </div>
</form>

          </div>
          <br>
          <semipolar-spinner class="spinner" v-if="isloading"
                             :animation-duration="2000"
                             :size="40"
                             color="#ff1d5e"
          />
          <hr class=" ml-3 mr-3 sidebar-divider" >
        <div class="card shadow mb-4 ml-4 mr-4" v-if="responsedata">

          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Returned Results</h6>
          </div>
          <table class="table table-hover" >

              <thead>
        

                                        <tr v-if="profiledata.faceMatches.length > 0" >
                                            <th>Result</th>
                                            <td class="table-success">Face Matched</td>
                                        </tr>

                                        <tr v-if="profiledata.faceMatches.length > 0">
                                            <th>Confidence</th>
                                            <td>{{profiledata.faceMatches[0].face.confidence}}</td>
                                        </tr>

                                        <tr v-if="profiledata.unmatchedFaces.length > 0">
                                            <th>Result</th>
                                            <td class="table-danger">No Face Matched</td>
                                        </tr>

                                        <tr v-if="profiledata.unmatchedFaces.length > 0">
                                            <th>Confidence</th>
                                            <td>{{profiledata.unmatchedFaces[0].confidence}}</td>
                                        </tr>
                                   



                                    </thead>
          </table>

        </div>
        </div>

      </div>
      <div class="col-lg-4">

        <div class="card position-relative">
          <div class="card-body">
            <img class="card-img-top"
                 src="../../assets/img/undraw_Hire_re_gn5j.svg">
            <hr>
          </div>
        </div>

      </div>
    </div>


   
  </div>
</template>

<script>
import axios from "axios";
import dashboard from "../../layouts/dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SemipolarSpinner  } from 'epic-spinners';
export default {
  name: "FaceCompare",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
  },
  data() {
    return {
      
      base64img: "http://placehold.it/180",
      base64img1: "http://placehold.it/180",
      imgSrc: null,
      imgSrc1:null,
      responsedata: false,
      profiledata: "",
      isloading: false,

    }
  },
  methods:{
     async comparecall(e){

    const file = this.$refs.file.files[0];
    const file1 = this.$refs.file1.files[0];

    if (!file || !file1) {
        e.preventDefault();
        alert('File(s) not chosen');
        return;
        }
    else{
     this.imgSrc = this.base64img.split(",")[1]
     this.imgSrc1 = this.base64img1.split(",")[1]
      const data = {
          sourceBase64String: this.imgSrc,
          targetBase64String: this.imgSrc1

      }
      this.isloading = true;
      await  new Promise(resolve => setTimeout(resolve, 2000));
      try{
      let JWTToken = this.$store.getters.accessToken;
      axios.post('https://ekyctest.xarani.net/service/facecompare/ ',data , { headers: {"Authorization" : `Bearer ${JWTToken}`} })
              .then(res => {
                if(res.status === 200){
                  this.profiledata = res.data;
                  console.log('profile is:', res.data);
                  console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                  this.responsedata = true;
                  this.isloading = false;
                }else{
                  window.clearTimeout(this.timeoutID);
                  this.$store.commit("clearUserData");
                  alert('Your Session has ended');
                  // this.$router.push("/login");
                  window.location.href = '/login';
                }

              })
              .catch(error => console.log(error))
      }catch(e){
        console.error(e)
      }}
    },

    async generatePDF(){
      let pdf = new jsPDF("p","pt","a2");
      let element = document.getElementById('capturePdf');
      let width= element.style.width;
      let height = element.style.height;
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 2, 2, width, height);
        pdf.save('test.pdf')
      });
    },

    async clear(){
      this.responsedata = false,
    this.base64img = "http://placehold.it/180",
      this.base64img1 = "http://placehold.it/180",
      this.imgSrc = '',
      this.imgSrc1 = ''
      
     
    },

     handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },


     handleImage1(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image1(selectedImage);
    },


    

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        
        this.base64img = e.target.result;
        
       
      };
      reader.readAsDataURL(fileObject);
    },

      createBase64Image1(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        
        this.base64img1 = e.target.result;
        
      };
      reader.readAsDataURL(fileObject);
    },

 
  },

};
</script>

<style scoped>
  .pdf{ color:red}
  .spinner{
    margin-left: 30em;
  }
  
  .body-main {
    background: #ffffff;
    border-bottom: 15px solid #1E1F23;
    border-top: 15px solid #1E1F23;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 40px 30px !important;
    position: relative;
    box-shadow: 0 1px 21px #808080;
    font-size: 10px;
    width:200px;
  }

  .main thead {
    background: #1E1F23;
    color: #fff
  }

  .img {
    height: 80px
  }

.column.im{
    margin-left: 15px;
    margin-bottom: 30px;
}



  .im1{
      max-width: 180px !important;
      max-height: 180px !important
  }

  h1 {
    text-align: center
  }

  tr.hide-table-padding td {
  padding: 0;
}

.expand-button {
	position: relative;
}

.accordion-toggle .expand-button:after
{
  position: absolute;
  right:2rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-';
}
.accordion-toggle.collapsed .expand-button:after
{
  content: '+';
}

.btn1{
    margin-right: 2em;
}
</style>