<template>
  <div id="app">
    <!-- Page Wrapper -->
    <component :is="layout">
      <router-view :layout.sync="layout"></router-view>
    </component>
  </div>
</template>

<script>


// import Sidebar from "@/components/navigation/Sidebar";
// import TopNav from "@/components/navigation/TopNav";

export default {
  name: 'App',
  // components: {
  //   TopNav,
  //   Sidebar
  //
  // }
  data () {
    return {
      layout: `div`,
      // timeoutID: "",
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
