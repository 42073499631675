<template>
  <div>
    <!--    <div id="wrapper">-->

    <div class="top-nav">
      <TopNav></TopNav>
    </div>


    <div class="">
      <div class="row ">
        <div class="col-2">
          <div class="sidebar-div">
            <Sidebar></Sidebar>
          </div>
        </div>
        <div class="col">
          <div class="body-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <a class="btn btn-primary" @click="logout"><i class="fas fa-fw fa-cog"></i>
              <span>Logout</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Sidebar from "../components/navigation/Sidebar";
import TopNav from "../components/navigation/TopNav";
import Footer from "@/components/navigation/Footer";

export default {
  name: "dashboard",
  components: {
    TopNav,
    Sidebar,
    Footer

  },
  data: () => {
    return {
      timeoutID: ""
    };
  },
  created() {
    this.setup();
    console.log("timer started");

  },
  methods: {
    async logout() {
      // localStorage.clear();
      this.$store.commit("clearUserData");
      window.location.href = '/login'
    },
    setup() {
      // if (process.client) {
      addEventListener("mousemove", this.resetTimer, false);
      addEventListener("mousedown", this.resetTimer, false);
      addEventListener("keypress", this.resetTimer, false);
      addEventListener("DOMMouseScroll", this.resetTimer, false);
      addEventListener("mousewheel", this.resetTimer, false);
      addEventListener("touchmove", this.resetTimer, false);
      addEventListener("MSPointerMove", this.resetTimer, false);

      this.startTimer();
      console.log("timer started from setup");
      // }
    },
    startTimer() {
      this.timeoutID = window.setTimeout(this.goInactive, 300000);


    },
    resetTimer(e) {
      window.clearTimeout(this.timeoutID);

      this.goActive();
    },
    goActive() {
      this.startTimer();
    },
    goInactive() {

      window.clearTimeout(this.timeoutID);
      this.$store.commit("clearUserData");
      alert('Your Session has ended');
      // this.$router.push("/login");
      window.location.href = '/login';
    },
  },

}
</script>

<style scoped>
.sidebar-div {
  position: fixed;
  height: 100vh;
  left: 0;
  z-index: 2;
}

.top-nav {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
}

.body-content {
  z-index: 0;
  padding-top: 100px;
  padding-left: 20px;
  /*border: green 2px solid;*/
}

</style>