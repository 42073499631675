<template>
  <div>
    <div class="d-sm-flex mb-4">
      <h1 class="h3 mb-0 text-gray-800">Sanction Screening</h1>

    </div>

    <div class="row">
      <!-- Content Column -->
      <div class="col-lg-8 mb-4 animated bounceInUp">
        <!-- Color System -->

        <div class="card shadow mb-4">
          <div class="card-body">

            <form method="get" @submit.prevent="sanctcall">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="inputname">First Name</label>
                <div class="col-sm-10">
                  <input id="inputname" v-model="firstname" :class="{ 'is-invalid': submitted && $v.firstname.$error }" class="form-control"
                         type="text">
                  <div v-if="submitted && $v.firstname.$error" class="invalid-feedback">
                    <span v-if="!$v.firstname.required">Name is required</span>
                    <span v-if="!$v.firstname.minLength">Name must be at least 4 characters</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="inputMN">Middle Name</label>
                <div class="col-sm-10">
                  <input id="inputMN" v-model="middlename" class="form-control" type="text">

                </div>

              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="inputsurname">Surname</label>
                <div class="col-sm-10">
                  <input id="inputsurname" v-model="surname" :class="{ 'is-invalid': submitted && $v.surname.$error }" class="form-control"
                         type="text">
                  <div v-if="submitted && $v.surname.$error" class="invalid-feedback">
                    <span v-if="!$v.surname.required">Surname is required</span>

                  </div>
                </div>

              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label" for="inputdob">Date Of Birth</label>
                <div class="col-sm-10">


                  <input id="inputdob" v-model="dateOfBirth" class="form-control" max="2000-12-31" min="1900-01-01"
                         placeholder="dd-mm-yyyy" type="date" value="" @change="format">
                </div>

              </div>


              <div class="row">
                <div class="col-sm-12 text-right">
                  <button class="btn btn-success mb-2 btn1" type="button" @click="sanctcall">Start Screening</button>
                  <button class="btn btn-warning mb-2" type="submit" @click="clear">Clear Results</button>
                </div>
              </div>
            </form>

          </div>
          <br>
          <semipolar-spinner v-if="isloading" :animation-duration="2000"
                             :size="40"
                             class="spinner"
                             color="#ff1d5e"
          />
          <hr class=" ml-3 mr-3 sidebar-divider">
          <div v-if="responsedata" class="card shadow mb-4 ml-4 mr-4">

            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Returned Results</h6>
            </div>
            <table class="table table-hover">

              <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Requested Name</td>
                <td></td>
                <td>{{ profiledata.nameRequested }}</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Requested Surname</td>
                <td></td>
                <td>{{ profiledata.surnameRequested }}</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Match Results</td>
                <td></td>
                <td v-if="profiledata.matchScore === null" class="table-danger">No Match</td>
                <td v-else-if="profiledata.matchScore < 0.4" class="table-warning">Partial Match on Name/Surname</td>
                <td v-else-if="profiledata.matchScore < 0.7" class="table-primary">Partial Match on Name and Surname
                </td>
                <td v-else-if="profiledata.matchScore < 1.0" class="table-info">Match except for middle name</td>
                <td v-else class="table-success">Perfect Match</td>
              </tr>
              <tr id="accordion1" class="accordion-toggle collapsed" data-parent="#accordion1" data-toggle="collapse"
                  href="#collapseOne">
                <th scope="row">4</th>
                <td>Match Details</td>

                <td></td>
                <td class="expand-button">{{ profiledata.matchDetails.length }} results</td>


              </tr>
              <tr class="hide-table-padding">
                <td></td>
                <td colspan="3">
                  <div id="collapseOne" class="collapse in p-3">
                    <div v-for="(detail, index) in profiledata.matchDetails" :key='index' class="row">

                      <p style="text-align:left">{{ index + 1 }}: {{ detail }}</p>
                    </div>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>

          </div>
        </div>

      </div>
      <div class="col-lg-4">

        <div class="card position-relative">
          <div class="card-body">
            <img class="card-img-top"
                 src="../../assets/img/undraw_Hire_re_gn5j.svg">
            <hr>
          </div>
        </div>

      </div>
    </div>

    <!--    HTML REPORT-->
    <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" role="dialog"
         tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <div id="capturePdf" class="container">
            <div class="row">
              <div class="col-md-12 body-main">
                <div class="row">
                  <div v-if="false" class="col-md-4">
                    <img alt="Invoce Template" class="img" src="../../assets/img/undraw_Hire_re_gn5j.svg"/>
                  </div>
                  <div v-if="false" class="col-md-8 text-right">
                    <h4 style="color: #F81D2D;"><strong>Zara</strong></h4>
                    <p>221 ,Baker Street</p>
                    <p>1800-234-124</p>
                    <p>example@gmail.com</p>
                  </div>
                </div>
                <br/>

                <div>
                  <table class="table table-hover">

                    <tbody>
                    <tr>

                      <td>Person Name / Names</td>
                      <td></td>
                      <td>{{ profiledata.firstName }}</td>
                    </tr>
                    <tr>

                      <td>Person Surname</td>
                      <td></td>
                      <td>{{ profiledata.surname }}</td>
                    </tr>
                    <tr>

                      <td>Person ID Number</td>
                      <td></td>
                      <td v-if="profiledata.status === 'E'" class="table-danger">{{ profiledata.personNo }} Not Valid
                      </td>
                      <td v-else>{{ profiledata.personNo }}</td>
                    </tr>
                    <tr>

                      <td>Person Gender</td>
                      <td></td>
                      <td v-if="profiledata.sex === 'F'">Female</td>
                      <td v-else-if="profiledata.sex === 'M'">Male</td>
                      <td v-else>Null</td>
                    </tr>

                    <tr>

                      <td>Person Date Of Birth</td>
                      <td></td>
                      <td>{{ profiledata.dateOfBirth }}</td>
                    </tr>
                    <tr>

                      <td>Person Birth Place</td>
                      <td></td>
                      <td>{{ profiledata.birthPlace }}</td>
                    </tr>
                    <tr v-if="profiledata.status === 'D'">

                      <td>Person Date Of Death</td>
                      <td></td>
                      <td>{{ profiledata.dateOfDeath }}</td>
                    </tr>

                    <tr>

                      <td>Person Life Status</td>
                      <td></td>
                      <td v-if="profiledata.status === 'A'" class="table-success">Active</td>
                      <td v-else-if="profiledata.status === 'E'" class="table-danger">Record Error / Not Valid</td>
                      <td v-else-if="profiledata.status === 'D'" class="table-warning">Deceased</td>
                      <td v-else>Null</td>
                    </tr>
                    <hr class=" ml-3 mr-3 sidebar-divider">

                    </tbody>
                  </table>

                </div>
                <div>
                  <div class="col-md-12">
                    <p><b>Date :</b>
                      {{ new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() }}</p><br/>
                    <p><b>Signature</b></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" type="submit" @click="generatePDF">
            <i class="fas fa-file-pdf fa-sm text-white-50 pdf"></i> Generate Report
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dashboard from "../../layouts/dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {SemipolarSpinner} from 'epic-spinners';
import moment from 'moment';
import {minLength, required} from 'vuelidate/lib/validators';


export default {
  name: "Sanctions",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
  },
  data() {
    return {
      firstname: "",
      surname: "",
      middlename: "",
      dateOfBirth: "",
      dob: '',
      responsedata: false,
      profiledata: "",
      isloading: false,
      submitted: false,

    }
  },

  validations: {
    firstname: {
      required,
      minLength: minLength(2),
    },
    surname: {
      required,

    }
  },

  methods: {
    async sanctcall() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return;
      } else {
        this.isloading = true;
        await new Promise(resolve => setTimeout(resolve, 2000));
        try {
          let JWTToken = this.$store.getters.accessToken;
          axios.get('https://ekyctest.xarani.net/amlcheck/?firstname=' + this.firstname + '&surname=' + this.surname + '&middlename=' + this.middlename + '&dateOfBirth=' + this.dob, {headers: {"Authorization": `Bearer ${JWTToken}`}})
              .then(res => {
                if (res.status === 200) {
                  this.profiledata = res.data;
                  console.log('profile is:', res.data);
                  console.log(new Date().getMonth() + 1 + '-' + new Date().getFullYear());
                  this.responsedata = true;
                  this.isloading = false;
                } else {
                  window.clearTimeout(this.timeoutID);
                  this.$store.commit("clearUserData");
                  alert('Your Session has ended');
                  // this.$router.push("/login");
                  window.location.href = '/login';
                }

              })
              .catch(error => console.log(error))
        } catch (e) {
          console.error(e)
        }
      }
    },

    async format() {
      this.dob = moment(String(this.dateOfBirth)).format('DD-MM-YYYY')
    },

    async generatePDF() {
      let pdf = new jsPDF("p", "pt", "a2");
      let element = document.getElementById('capturePdf');
      let width = element.style.width;
      let height = element.style.height;
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 2, 2, width, height);
        pdf.save('test.pdf')
      });
    },

    async clear() {
      this.responsedata = false,
          this.firstname = "",
          this.surname = "",
          this.middlename = "",
          this.dateOfBirth = ""

    }

  },

};
</script>

<style scoped>
.pdf {
  color: red
}

.spinner {
  margin-left: 30em;
}

.body-main {
  background: #ffffff;
  border-bottom: 15px solid #1E1F23;
  border-top: 15px solid #1E1F23;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px !important;
  position: relative;
  box-shadow: 0 1px 21px #808080;
  font-size: 10px;
  width: 200px;
}

.main thead {
  background: #1E1F23;
  color: #fff
}

.img {
  height: 80px
}

h1 {
  text-align: center
}

tr.hide-table-padding td {
  padding: 0;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-';
}

.accordion-toggle.collapsed .expand-button:after {
  content: '+';
}

.btn1 {
  margin-right: 2em;
}
</style>