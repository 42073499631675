import Home from "../../Home";
import login from "../../authentication/login";
import Registry from "../../services/Registry";
import Sanctions from "../../services/Sanctions";
import Liveness from "../../services/Liveness";
import FaceCompare from "../../services/FaceCompare";
import Fcb from "../../services/Fcb"
import VueRouter from "vue-router";
import store from "../../../store/index";
import BatchUploadResults from "@/components/services/BatchUploadResults";


const routes = [
    {
        path: '/',
        component: Home,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },
    {
        path: '/login',
        component: login
    },
    {
        path: '/identity',
        component: Registry,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },

    {
        path: '/sanctions',
        component: Sanctions,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },
    {
        path: '/liveness',
        component: Liveness,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },
    {
        path: '/facecompare',
        component: FaceCompare,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },

    {
        path: '/fcb',
        component: Fcb,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },

    {
        name: 'batch-upload-results',
        path: '/batch-upload-results/:batchId',
        component: BatchUploadResults,
        props: true,
        beforeEnter: (to, from, next) => {
            if (store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },

];

const router = new VueRouter(
    {
        routes,
        mode: 'history'
    }
);

// router.beforeEach((to) =>{
//     console.log(to);
// })

export default router;