<template>
  <div class="col-xl-10 col-lg-12 col-md-9">
    <div class="align-middle">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">Login</h1>
                </div>

                <div v-show="hasErrors" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <form class="user" @submit.prevent="login">
                  <div class="form-group">
                    <input class="form-control form-control-user" required
                           v-model="username" aria-describedby="emailHelp"
                           placeholder="Enter Username">
                  </div>
                  <div class="form-group">
                    <input type="password" required class="form-control form-control-user"
                           v-model="password" placeholder="Password">
                  </div>

                  <a type="submit" @click="login" class="btn btn-primary btn-user btn-block">
                    Login
                  </a>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6 ml-5">
                      <semipolar-spinner v-if="isloading"
                                         :animation-duration="1500"
                                         :size="65"
                                         color="#ff1d5e"/>
                    </div>
                    <div class="col-sm-3"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../layouts/auth";
import {mapMutations} from "vuex";
import axios from "axios";
import {SemipolarSpinner} from 'epic-spinners'
import * as bootstrap from "bootstrap";

export default {
  name: "login",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, auth);
  },

  data: () => {
    return {
      username: "",
      password: "",
      isloading: false,
      errorMessage: "",
      hasErrors: false

    };
  },

  methods: {
    ...mapMutations(["setAccessToken", "setRefreshToken"]),
    // ...mapActions(["logIn"]),

    async login(e) {
      // dispatch
      this.isloading = true;
      // let myModal = new bootstrap.Modal(document.getElementById('exampleModalCenter'), {});
      // myModal.show();
      const payload = {
        //admin Xarani@2021
        username: this.username,
        password: this.password,
      };
      await new Promise(resolve => setTimeout(resolve, 3000));
      await axios.post("https://ekyctest.xarani.net/service/token/", payload)
          .then(response => {
            if (response.status === 200) {
              this.$store.commit("setRefreshToken", response.data.refresh);
              this.$store.commit("setAccessToken", response.data.access);
              this.$store.commit("setAuthenticated", true);
              this.isloading = false;
              console.log(this.$store.getters.accessToken);
              console.log(this.$store.getters.refreshToken);
              console.log(this.$store.getters.isAuthenticated);

              this.$router.push("/");
            } else {
              this.errorMessage = "Something went wrong please try again";
              this.isloading = false;
              this.hasErrors = true;
            }
          })
          .catch(error => {

            if (error.status && error.status === 401) {
              this.errorMessage = "Error, incorrect username or password";
            } else {
              this.errorMessage = "Something went wrong please try that again";
            }

            this.isloading = false;
            this.hasErrors = true;

          });
    },
  },
};
</script>

<style scoped>
body {
  background-color: #4e73df;
  background-size: cover;
}
</style>
